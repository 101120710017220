import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const Page404HeroText = () => (
  <StyledDiv className='container flex flex-col items-center'>
    <h1 className='font-display font-extrabold text-5xl text-white text-center'>
      Page Not Found
    </h1>

    <h2 className='font-body text-2xl text-white mb-4 text-shadow text-center'>
      404 Page Error
    </h2>

    <Link to='/'>
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">
        Go to home page
      </button>
    </Link>
  </StyledDiv>
)

export default Page404HeroText