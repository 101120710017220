import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import Page404ColorBg from '../components/headers/Page404ColorBg'
import Page404HeroText from "../components/headers/Page404HeroText"

const NotFoundPage = () => (
  <MainContainer>
    <HeadData
      title='404 Page'
      description='Page not found.'
    />

    <Page404ColorBg>
      <Page404HeroText />
    </Page404ColorBg>
  </MainContainer>
)

export default NotFoundPage
